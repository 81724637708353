// import Vue from 'vue'
// currency filter in vue-i18n

export function currency(value) {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function capitalize(value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function temperature(value) {
  if (!value) {
    return ''
  }
  return `${value}°`
}

export function thspToNbsp(text) {
  return (text || '').replace(' ', ' ')
}

export function markdown(text) {
  return markdownParser(text)
}
